import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  LinearProgress,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import LoaderBar from "components/LoaderBar/LoaderBar";

// styles
import useStyles from "../control/styles";

import DateField from "components/CustomFields/DateField.js";
import {
  getCurrentDate,
  getLastMonthDate,
  getNextMonthDate,
} from "utils/dateGetters.js";
import { dbDelete, dbGet } from "utils/DBFetchers.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import { format } from "date-fns";
import logo from "images/logoPagesRS.jpg";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { getRoleName } from "utils/roleNameGetter";
import createColumns from "pages/audit/tablesUtils/BurnableSales";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const blankFilters = {
  begin: getDateFormatted(
    new Date(getCurrentDate().getFullYear(), getCurrentDate().getMonth(), 1),
  ),
  beginDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  end: getDateFormatted(
    new Date(
      getNextMonthDate().getFullYear(),
      getNextMonthDate().getMonth(),
      1,
    ),
  ),
  endDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  distributors: "",
  pharmagroups: "",
  pharmacies: "",
};

const blankAllResults = {
  distributors: [],
  pharmagroups: [],
  pharmacies: [],
};

const selectorOptions = [
  { value: "distributors", label: "Distribuidores" },
  { value: "pharmagroups", label: "Grupos" },
  { value: "pharmacies", label: "Farmacias" },
];

const mapErrors = {
  1: "Cupón inexistente",
  2: "Inscripción inexistente",
  3: "Cupón ya utilizado",
  4: "Farmacia inexistente",
  5: "Distribuidor inexistente",
  6: "Presentación inexistente",
  7: "Vademecum inexistente",
  13: "Ventas mensuales excedidas",
  14: "Paciente inexistente",
};

const mapWarnings = {
  8: "Presentación incorrecta",
  9: "Unidades exceden el máximo",
  10: "Descuento % informado incorrecto",
  11: "Descuento $ informado incorrecto",
  12: "Costo informado incorrecto",
  15: "Cupón ya utilizado",
  16: "Presentación autocompletada",
};

export const parseGetAllErrors = (errors) => {
  var allErrors = errors?.map((elem) =>
    elem.error_id in mapErrors ? mapErrors[elem.error_id] : elem.error_id,
  );
  return allErrors.join(" - ");
};

export const parseGetAllWarnings = (warnings) => {
  var allWarnings = warnings.map((elem) =>
    elem.error_id in mapWarnings ? mapWarnings[elem.error_id] : elem.error_id,
  );
  return allWarnings.join(" - ");
};

export default function SalesListPage() {
  const role = getRoleName();
  const hasPermissions =
    role === "admin" || role === "super_admin" || role === "full";
  const ERRORS_POS = 21;
  const WARNINGS_POS = 22;

  var classes = useStyles();
  const [filters, setFilters] = useState(blankFilters);
  const [allResults, setAllResults] = useState(blankAllResults);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tableData, setTableData] = useState([]);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [deleteSalePopup, setDeleteSalePopup] = useState({
    status: false,
    name: "",
    id: "",
    pharmacy: "",
    rut: "",
  });

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };
  const handleAutoCompletePharmacyChange = (event, newValue) => {
    setFilters({ ...filters, pharmacies: newValue ? newValue.id : "" });
  };
  const handleBeginDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      beginDate: dateOnFirstDay,
      begin: getDateFormatted(dateOnFirstDay),
    });
  };
  const handleEndDateChange = (date) => {
    var dateOnFirstDayNext = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1,
    );
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      endDate: dateOnFirstDay,
      end: getDateFormatted(dateOnFirstDayNext),
    });
  };
  const validDateFilters = () => {
    let validDate =
      filters.beginDate &&
      filters.endDate &&
      filters.beginDate > filters.endDate
        ? false
        : true;
    return validDate;
  };

  const handleApplyFilters = () => {
    if (!validDateFilters()) {
      snackbarOpen("error", "Rango de fechas inválido");
      return;
    }
    setTableData([]);

    let pharmacyId = `${
      filters.pharmacies ? `&pharmacy_id=${filters.pharmacies}` : ""
    }`;
    let distributorId = `${
      filters.distributors ? `&distributor_id=${filters.distributors}` : ""
    }`;
    let pharmagroupId = `${
      filters.pharmagroups ? `&pharmagroup_id=${filters.pharmagroups}` : ""
    }`;

    let programId = `&program_id=${localStorage.getItem("programId")}`;

    setIsLoading(true);
    //?from_date=<dd/mm/yy>&to_date=<dd/mm/yy>
    dbGet(
      `sales?from_date=${filters.begin}&to_date=${filters.end}${pharmacyId}${distributorId}${pharmagroupId}&burnable=true`,
    )
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos");
        setTableData([]);
        setIsLoading(false);
      });
  };
  const handleCleanFilters = () => {
    setFilters(blankFilters);
    setSelectedCategory("");
  };

  const handleSetCategory = (value) => {
    setSelectedCategory(value);
    if (allResults[value].length === 0) getResults(value);
  };

  var [isLoading, setIsLoading] = useState(false);
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        if (category === "distributors") {
          data = data.filter((x) => x.works_with_independents && x.id !== 19);
        }
        data.push({ id: "", name: "Sin filtro" });
        let mapped = data.map((item) => {
          return {
            id: item["id"],
            name:
              category === "pharmacies"
                ? `${item["name"]} - ${item["code"]}`
                : `${item["name"]}`,
          };
        });
        setAllResults({
          ...allResults,
          [category]: mapped,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    setTableData([]);
    handleApplyFilters();
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!tableData) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  const handleDeleteSaleOpen = (name, sale, pharmacy, rut) => {
    setDeleteSalePopup({
      ...deleteSalePopup,
      status: true,
      name: name,
      id: sale,
      pharmacy: pharmacy,
      rut: rut,
    });
  };

  const handleDeleteSalePopupClose = () => {
    setDeleteSalePopup({
      ...deleteSalePopup,
      status: false,
      name: "",
      id: "",
      pharmacy: "",
      rut: "",
    });
  };

  const handleDeleteSale = () => {
    handleDeleteSalePopupClose();
    dbDelete(`burnable/sale/${deleteSalePopup.id}`)
      .then((data) => {
        if (data) {
          snackbarOpen("success", `Venta eliminada exitosamente.`);
        }
        setTimeout(() => {
          setTableData([]);
          handleApplyFilters();
        }, 700);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al eliminar la venta",
        );
        setIsLoading(false);
        return;
      });
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((response) => {
        if (response.status === "Completed") {
          let tableData = [];
          tableData = response.data.map((row) => [
            "",
            row.id,
            format(new Date(row.transaction_date + " EDT"), "dd/MM/yyyy"),
            row.distributor?.name,
            row.pharmacy_code,
            "",
            "",
            row.source === 2 ? "Archivo" : "Web",
            row.patient?.rut,
            row.patient?.id,
            row.informed_presentation?.name,
            row.inscribed_presentation?.name,
            row.informed_units,
            row.informed_exlab_price,
            row.informed_discount_percentage,
            row.informed_discount,
            row.calculated_units,
            row.calculated_exlab_price,
            row.calculated_discount_percentage,
            row.calculated_discount,
            "",
            row.errors?.length === 0 ? "OK" : parseGetAllErrors(row.errors),
            row.warnings?.length === 0
              ? "OK"
              : parseGetAllWarnings(row.warnings),
          ]);
          setTableData(tableData);
          setIsLoading(false);
        } else if (!response.error_status && response.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, response), 2000);
        } else {
          snackbarOpen("error", "Error al obtener los datos de la consulta");
          setTableData([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos de la consulta");
        setIsLoading(false);
      });
  };

  const columns = createColumns(false, handleDeleteSaleOpen);

  return (
    <>
      {/* <DeletePopUp
      title={"Eliminar venta"}
      closeFunction={handleDeleteSalePopupClose}
      
    > */}
      <Dialog
        open={deleteSalePopup.status}
        onClose={handleDeleteSalePopupClose}
      >
        <DialogTitle>{`Eliminar venta`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Va a eliminar la venta de {deleteSalePopup.name} al paciente con RUT{" "}
            {deleteSalePopup.rut} realizada en la farmacia{" "}
            {deleteSalePopup.pharmacy}. Esto es una operación irreversible ¿Está
            seguro de esto?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDeleteSalePopupClose}
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteSale}
            color="secondary"
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title="Ventas Realizadas Quemables"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.smallItems}>
              <Typography variant="h4" className={classes.text}>
                Selección
              </Typography>
            </div>
            <div className={classes.smallItems}>
              <Typography className={classes.text} weight="bold">
                Período
              </Typography>
              <DateField
                helperText="Fecha desde (mes y año)"
                name="begin"
                views={["month", "year"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.beginDate ? filters.beginDate : getCurrentDate()}
                onChange={handleBeginDateChange}
                fieldClassName={classes.smallField}
              />
            </div>
            <div
              className={classes.smallItems}
              style={{ justifyContent: "flex-end" }}
            >
              <DateField
                helperText={
                  !validDateFilters()
                    ? "Fecha hasta no puede ser menor a fecha desde"
                    : "Fecha hasta (mes y año)"
                }
                name="end"
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.endDate ? filters.endDate : getCurrentDate()}
                onChange={handleEndDateChange}
                fieldClassName={classes.smallField}
                error={!validDateFilters()}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} style={{ marginTop: "0.5%" }}>
            {hasPermissions && (
              <div className={classes.generalPurposeItems}>
                <TextField
                  id="selection"
                  select
                  className={classes.selectorField}
                  value={selectedCategory}
                  onChange={(e) => {
                    handleSetCategory(e.target.value);
                    setFilters({
                      ...filters,
                      distributors: "",
                      pharmagroups: "",
                      pharmacies: "",
                    });
                  }}
                  label="Seleccione"
                  helperText="Categoría"
                  variant="outlined"
                  margin="dense"
                >
                  {selectorOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {selectedCategory !== "pharmacies" ? (
                  <TextField
                    id="selectedCategory"
                    select
                    className={classes.generalPurposeField}
                    label="Seleccione"
                    name={selectedCategory}
                    value={selectedCategory ? filters[selectedCategory] : ""}
                    onChange={handleChange}
                    variant="outlined"
                    margin="dense"
                  >
                    {(selectedCategory ? allResults[selectedCategory] : []).map(
                      (option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                ) : (
                  <Autocomplete
                    freeSolo
                    style={{ marginTop: "-0.5%" }}
                    onChange={handleAutoCompletePharmacyChange}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    options={allResults.pharmacies ? allResults.pharmacies : []}
                    renderInput={(params) => (
                      <TextField
                        className={classes.generalPurposeField}
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </div>
            )}

            <div className={classes.buttonSpacing}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                disabled={isLoading}
              >
                Aplicar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCleanFilters}
                disabled={isLoading}
              >
                Limpiar
              </Button>
            </div>
          </Grid>
        </Grid>

        {isLoading ? (
          <LoaderBar />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomMUIDataTable
                title="Ventas realizadas"
                data={tableData ? tableData : undefined}
                columns={columns}
                filterStart={1}
                setRowProps={(row) => {
                  // Colors cells with green if no errors, orange if warnings, red if errors
                  if (row[ERRORS_POS] === "OK" && row[WARNINGS_POS] === "OK") {
                    return {
                      style: { background: "rgba(134, 227, 127, 0.5)" },
                    };
                  } else if (row[ERRORS_POS] !== "OK") {
                    return {
                      style: { background: "rgba(245, 122, 118, 0.5)" },
                    };
                  } else if (row[WARNINGS_POS] !== "OK") {
                    return {
                      style: { background: "rgba(255, 165, 0, 0.5)" },
                    };
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
