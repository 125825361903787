const routes = {
  app: "/app",
  root: "/",
  notFound404: "/app/404",
  login: "/login",
  passwordResetRequest: "/olvide-mi-contraseña",
  passwordResetForm: "/cambiar-clave",
  soloMujerForm: "/solomujer",
  burnableCouponForm: "/quemables",
  acosBurnableCouponForm: "/acos",
  hormBurnableCouponForm: "/horm",
  acuodeBurnableCouponForm: "/acuode",
  home: "/app/inicio",
  profile: "/app/perfil",
  audit: "/app/auditoria",
  auditControlPanel: "/app/auditoria/tablero",
  auditInscriptions: "/app/auditoria/empadronamientos",
  auditSales: "/app/auditoria/ventas",
  burnableAuditSales: "/app/auditoria/quemables-ventas",
  auditDataQuality: "/app/auditoria/datos",
  auditDigitalInscription: "/app/auditoria/inscripcion-digital",
  auditApprovalWorkflow: "/app/auditoria/aprobacion",
  auditPatterns: "/app/auditoria/patrones",
  auditImport: "/app/auditoria/importar",
  auditUF: "/app/auditoria/uf",
  traceability: "/app/traceability",
  control: "/app/control",
  controlCuponesIngresados: "/app/control/cuponesIngresados",
  controlVentasRealizadas: "/app/control/ventasIngresadas",
  checkDoctor: "/app/ui/check_doctor",
  benefits: "/app/beneficios",
  benefitsNewCupon: "/app/beneficios/nuevo-cupon",
  benefitsNewSale: "/app/beneficios/nueva-venta",
  benefitsNewSaleBurnable: "/app/quemables/venta-quemable",
  benefitsNewSaleBurnableNoCoupon: "/app/quemables/venta-quemable-sin-cupon",
  benefitsImport: "/app/beneficios/importar",
  benefitsImportBurnable: "/app/beneficios/importacion-quemables",
  benefitsConsultDoctor: "/app/beneficios/consulta-medico",
  benefitsConsultProduct: "/app/beneficios/consulta-paciente",
  benefitsImportBurnable: "/app/quemables/importacion-quemables",
  controlVentasRealizadasBurnable: "/app/quemables/ventas-realizadas",
  burnable: "/app/quemables",
  burnableNewSale: "/app/quemables/nueva-venta",
  burnableNewSaleNoCouponPharmacies:
    "/app/quemables/nueva-venta/nueva-venta-sin-cupon",
  burnableNewSaleCouponPharmacies:
    "/app/quemables/nueva-venta/nueva-venta-cupon",
  burnableSalesPharmacies: "/app/quemables/ventas",
  benefitsImportBurnablePharmacies: "/app/quemables/ventas/importar",
  controlVentasRealizadasBurnablePharmacies: "/app/quemables/ventas/resumen",
  users: "/app/usuarios",
  usersUsers: "/app/usuarios/usuarios",
  usersNewUser: "/app/usuarios/nuevo",
  usersRoles: "/app/usuarios/roles/roles",
  usersEditRole: "/app/usuarios/roles/editar",
  usersNewRole: "/app/usuarios/roles/nuevo",
  usersEditUser: "/app/usuarios/editar",
  generateVisitorQR: "/app/usuarios/qr",
  reports: "/app/reportes",
  reportsSettlements: "/app/reportes/liquidaciones",
  reportsConsultPatient: "/app/reportes/paciente",
  createReports: "/app/reportes/crear",
  dashboards: "/app/paneles",
  reportsDashboard: "/app/paneles/panel-de-control",
  reportsSMDashboard: "/app/paneles/panel-control-sm",
  reportsDashboardOld: "/app/paneles/ex-control-panel",
  reportsSMDashboardOld: "/app/paneles/ex-panel-sm",
  auditDashboard: "/app/paneles/panel-auditoria",
  traceabilityDashboard: "/app/paneles/trazabilidad",
  abm: "/app/altas-y-bajas/",
  abmMedics: "/app/altas-y-bajas/medicos/medicos",
  abmMedicsEdit: "/app/altas-y-bajas/medicos/editar",
  abmMedicsNew: "/app/altas-y-bajas/medicos/nuevo",
  abmVademecums: "/app/altas-y-bajas/vademecums/vademecums",
  abmVademecumsEdit: "/app/altas-y-bajas/vademecums/editar",
  abmVademecumsNew: "/app/altas-y-bajas/vademecums/nuevo",
  abmProducts: "/app/altas-y-bajas/productos/productos",
  abmProductsEdit: "/app/altas-y-bajas/productos/editar",
  abmProductsNew: "/app/altas-y-bajas/productos/nueva",
  abmPharmacies: "/app/altas-y-bajas/farmacias/farmacias",
  abmPharmaciesEdit: "/app/altas-y-bajas/farmacias/editar",
  abmPharmaciesNew: "/app/altas-y-bajas/farmacias/nueva",
  abmPharmaGroups: "/app/altas-y-bajas/grupos/grupos",
  abmDistributors: "/app/altas-y-bajas/distribuidores/distribuidores",
  abmDistributorsEdit: "/app/altas-y-bajas/distribuidores/editar",
  abmDistributorsNew: "/app/altas-y-bajas/distribuidores/nuevo",
  abmPharmacyDistributors:
    "/app/altas-y-bajas/farmacia-distribuidor/farmacia-distribuidor",
  abmPharmacyDistributorsEdit:
    "/app/altas-y-bajas/farmacia-distribuidor/editar",
  abmPharmacyDistributorsNew: "/app/altas-y-bajas/farmacia-distribuidor/nueva",
  abmBarcodes: "/app/cargar-codigos-de-barra",
  abmKam: "/app/altas-y-bajas/kam/kam",
  abmKamEdit: "/app/altas-y-bajas/kam/editar",
  abmKamNew: "/app/altas-y-bajas/kam/nuevo",
  abmVisitors: "/app/altas-y-bajas/visitadores/visitadores",
  abmVisitorsEdit: "/app/altas-y-bajas/visitadores/editar",
  abmVisitorsNew: "/app/altas-y-bajas/visitadores/nuevo",
  abmSalcobrand: "/app/altas-y-bajas/salcobrand/salcobrand",
  abmSalcobrandEdit: "/app/altas-y-bajas/salcobrand/editar",
  abmSalcobrandNew: "/app/altas-y-bajas/salcobrand/nuevo",
  delete: "/app/borrar",
  couponManagement: "/app/gestion-de-cupones/gestion-de-cupones",
  accreditCoupon: "/app/gestion-de-cupones/acreditar-cupones",
  sendCoupon: "/app/gestion-de-cupones/enviar-cupones",
  traditional: "/app/tradicionales",
  traditionalControl: "/app/tradicionales/control",
  reportsSettlementsPharmacies:
    "/app/tradicionales/control/liquidaciones-farmacias",
  controlVentasRealizadasPharmacies:
    "/app/tradicionales/control/ventas-realizadas",
  controlCuponesIngresadosPharmacies:
    "/app/tradicionales/control/cupones-ingresados",
  benefitsPharmacies: "/app/tradicionales/beneficios",
  benefitsNewCuponPharmacies: "/app/tradicionales/beneficios/nuevo-cupon",
  benefitsNewSalePharmacies: "/app/tradicionales/beneficios/nueva-venta",
  benefitsImportPharmacies: "/app/tradicionales/beneficios/importar",
  benefitsConsultProductPharmacies:
    "/app/tradicionales/beneficios/consulta-paciente",
};

export default routes;
