import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { DataGrid } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@mui/icons-material/Close";
import { dbGet, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";

// styles
import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { verifyRut } from "utils/rutValidations";
import RutField from "components/CustomFields/RutField";

const blankEditVisitorForm = {
  id: "",
  firstName: "",
  rut: "",
  rutVerifier: "",
  medics: null,
};

export default function ABMVisitorEditPage(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [editVisitorForm, updateForm] = useState(blankEditVisitorForm);
  const [isRutValid, setIsRutValid] = useState({ rut: true, message: "" });
  const [selectedMedics, setSelectedMedics] = useState([]);
  const [allMedics, setAllMedics] = useState([]);
  const [selectedMedicsIds, setSelectedMedicsIds] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [allPrograms, setAllPrograms] = useState([]);

  const availableMedicsColumns = [
    { field: "name", headerName: "Médicos", width: 600 },
  ];

  const selectedMedicsColumns = [
    {
      field: "",
      sortable: false,

      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Editar">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              size="small"
              onClick={() => {
                const medicFiltered = selectedMedics.filter(
                  (medic) => params.row.id !== medic.id,
                );
                setSelectedMedics([...medicFiltered]);
                setSelectedMedicsIds([
                  ...selectedMedicsIds.filter(
                    (medic) => params.row.id !== medic,
                  ),
                ]);
                var selected = medicFiltered.map((medic) => medic.id);
                updateForm({ ...editVisitorForm, medics: selected });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { field: "name", headerName: "Médicos seleccionados", width: 600 },
  ];

  const handleChange = (event) => {
    updateForm({
      ...editVisitorForm,
      [event.target.name]: event.target.value,
    });
  };

  const updateFormOnAvailable = (newSelect) => {
    setSelectedMedics([
      ...selectedMedics,
      ...allMedics.filter((medic) => newSelect.includes(medic.id)),
    ]);
    setSelectedMedicsIds([...selectedMedicsIds, ...newSelect]);
    var selected = selectedMedics.map((medic) => medic.id);
    updateForm({ ...editVisitorForm, medics: selected });
  };

  /* Redirect to visitor table page after adding correctly */
  const redirectToVisitorsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditVisitorForm);
      setIsLoading(false);
      history.push(routes.abmVisitors);
    }, 2000);
  };

  // Rut updaters
  const handleRutChange = (e) => {
    updateForm({
      ...editVisitorForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };

  const handleRutVerification = () => {
    var currentRut = editVisitorForm.rut + editVisitorForm.rutVerifier;
    dbGet(`traceability/rut-checker?rut=${currentRut}`)
      .then((data) => {
        if (data.exists)
          setIsRutValid({
            rut: false,
            message: "El RUT ingresado ya existe.",
          });
        else setIsRutValid({ rut: true, message: "" });
      })
      .catch();

    var rutValid = verifyRut(currentRut);
    if (rutValid && !editVisitorForm.rutVerifier)
      setIsRutValid({
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    else if (!rutValid) setIsRutValid({ rut: false, message: "RUT inválido." });
    else setIsRutValid({ rut: true, message: "" });
  };

  useEffect(() => {
    if (!editVisitorForm.rut && !editVisitorForm.rutVerifier) return;
    handleRutVerification();
  }, [editVisitorForm.rut, editVisitorForm.rutVerifier]);

  /* Cancel editing visitor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditVisitorForm);
    history.goBack();
  };

  const checkFormComplete = () => {
    var isCompleted = true;
    if (!editVisitorForm.firstName) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de nombre/s");
    } else if (!editVisitorForm.rut) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de RUT");
    } else if (!editVisitorForm.rutVerifier) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo del verificador del RUT");
    } else if (editVisitorForm.medics.length === 0) {
      isCompleted = false;
      snackbarOpen("error", "Selecciones los médicos");
    }
    // else if (!isRutValid.rut) {
    //   isCompleted = false;
    //   snackbarOpen("error", isRutValid.message);
    // }
    return isCompleted;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: editVisitorForm.firstName,
      // rut: editVisitorForm.rut,
      medics: editVisitorForm.medics.join(","),
    };

    // Sends to db
    dbPut(`consultants/${editVisitorForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Visitador editado exitosamente");
        redirectToVisitorsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.message ? error.message : "Error al editar el visitador",
        );
        setIsLoading(false);
        return;
      });
  };

  const getVisitorInfo = async (id) => {
    await dbGet(`consultants/${id}`)
      .then((data) => {
        let initialMedicsIds = data.medics
          ? data.medics.map((medic) => medic.id)
          : [];
        updateForm({
          ...editVisitorForm,
          id: data.id,
          rut: data.rut ? data.rut.slice(0, -1) : "",
          rutVerifier: data.rut ? data.rut.slice(-1)[0] : "",
          firstName: data.name ? data.name : "",
          medics: initialMedicsIds,
        });
        let mapped = data.medics
          ? data.medics.map((item) => ({
              id: item.id,
              name:
                (item.name ? item.name : "") +
                " | " +
                item.rut +
                " | " +
                (item.programs ? formatPrograms(item.programs) : ""),
            }))
          : [];
        setSelectedMedics(mapped);
        setSelectedMedicsIds(initialMedicsIds);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener el visitador");
      });
  };

  const getPrograms = async () => {
    try {
      const data = await dbGet("/programs");
      setAllPrograms(data);
    } catch (error) {}
  };

  const formatPrograms = (programs) => {
    const programNames = [];
    programs.forEach((programId) => {
      const program = allPrograms.find((p) => p.id === parseInt(programId));
      if (program) {
        programNames.push(program.name);
      }
    });
    const formattedString = programNames.join(", ");
    return formattedString;
  };

  /* Fills medic name options */
  const getMedics = async () => {
    try {
      const data = await dbGet("medics");
      if (!data.length) return;
      let mapped = data.map((item) => ({
        id: item.id,
        name:
          (item.name ? item.name : "") +
          " " +
          (item.last_name ? item.last_name : "") +
          " | " +
          item.rut +
          " | " +
          (item.programs ? formatPrograms(item.programs) : ""),
      }));
      setAllMedics(mapped);
    } catch (error) {
      console.log("Error occured.");
    }
  };

  useEffect(() => {
    getPrograms();
  }, []);

  /* On page load, get all data */
  useEffect(() => {
    if (!allPrograms.length) return;
    async function fetchData() {
      setIsLoadingData(true);
      await getMedics();
      await getVisitorInfo(props.props.location.state.visitorId);
      setIsLoadingData(false);
    }
    if (props.props.location.state && !allMedics.length) {
      fetchData();
    }
  }, [allPrograms]);

  return (
    <>
      <div>
        <PageTitle
          title="Editar visitador"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <Grid
          style={{ display: "flex", margin: 50 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.text}
                  style={{ marginTop: "10px" }}
                >
                  Datos del visitador
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    A continuación ingrese los datos del visitador a editar.
                  </Typography>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <Field
                    labelName="Nombre/s *"
                    required={true}
                    id="firstName"
                    disabled={isLoadingData}
                    value={editVisitorForm.firstName}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  />
                  <RutField
                    labelName="RUT * (sin guión)"
                    required={true}
                    id="rut"
                    id2="rutVerifier"
                    value={editVisitorForm.rut}
                    divClassType={classes.smallItems2}
                    divClassType2={classes.generalPurposeItems2}
                    fieldClassType={classes.smallFieldValidation2}
                    fieldClassType2={classes.smallFieldValidation3}
                    onChange={handleRutChange}
                    // helperText={isRutValid.message}
                    error={!isRutValid.rut}
                    disabled
                    value2={editVisitorForm.rutVerifier}
                    onChange2={handleRutChange}
                  />
                </div>
              </Grid>
              {!isLoadingData ? (
                <>
                  <Grid item xs={12} md={12} lg={6}>
                    <div className={classes.generalPurposeItems}>
                      <TextField
                        className={classes.searchField}
                        label="Filtrar"
                        onChange={(e) => {
                          setFilterModel({
                            items: [
                              {
                                columnField: "name",
                                operatorValue: "contains",
                                value: e.target.value,
                              },
                            ],
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <DataGrid
                      style={{ height: 600, width: 600 }}
                      className={classes.root}
                      rows={allMedics.filter(
                        (x) => !selectedMedicsIds.includes(x.id),
                      )}
                      columns={availableMedicsColumns}
                      filterModel={filterModel}
                      checkboxSelection
                      disableColumnFilter
                      showToolbar={true}
                      disableColumnSelector={true}
                      disableDensitySelector={true}
                      disableColumnMenu={true}
                      rowsPerPageOptions={[]}
                      pageSize={100}
                      localeText={{
                        ...dataGridTexts,
                        footerRowSelected: (count) => "",
                      }}
                      onSelectionModelChange={(GridRowSelectedParams) => {
                        updateFormOnAvailable(GridRowSelectedParams);
                      }}
                      onSelectionChange={(newSelection) => {
                        let newSelected = newSelection.rowIds.map((n) => +n);
                        updateFormOnAvailable(newSelected);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={6}
                    style={{ marginTop: "2.1%" }}
                  >
                    <Typography
                      variant="h5"
                      className={classes.text}
                      style={{ fontWeight: "bold" }}
                    >
                      Médicos seleccionados
                    </Typography>
                    <DataGrid
                      style={{ height: 600, width: 600 }}
                      rows={selectedMedics}
                      columns={selectedMedicsColumns}
                      showToolbar={true}
                      disableColumnSelector={true}
                      disableDensitySelector={true}
                      disableColumnMenu={true}
                      rowsPerPageOptions={[]}
                      pageSize={100}
                      localeText={{
                        ...dataGridTexts,
                        footerRowSelected: (count) => "",
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Box sx={{ width: "100%", margin: 20 }}>
                  <LinearProgress />
                </Box>
              )}
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12}>
                <div className={classes.buttonSpacing}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelAndGoBack}
                  >
                    Cancelar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
}
