import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";

// pages
import Dashboard from "pages/dashboard/Dashboard";
import ReportsDashboard from "pages/dashboard/GeneralDashboard";
import ReportsDashboardSM from "pages/dashboard/GeneralSMDashboard";
import ReportsDashboardOld from "pages/dashboard/GeneralDashboardOld";
import ReportsDashboardSMOld from "pages/dashboard/GeneralSMDashboardOld";
import DataQualityPage from "pages/audit/DataQualityPage";
import DigitalInscriptionPage from "pages/audit/DigitalInscriptionsPage";
import ControlPanelPage from "pages/audit/ControlPanelPage";
import ApprovalWorkflowPage from "pages/audit/ApprovalWorkflowPage";
import PatternsPage from "pages/audit/PatternsPage";
import AuditImportPage from "pages/audit/AuditImportPage";
import UFPage from "pages/audit/UFPage";
import InscriptionsPage from "pages/audit/InscriptionsPage";
import SalesPage from "pages/audit/SalesPage";
import TraceabilityPage from "pages/traceability/TraceabilityPage";
import CouponsListPage from "pages/control/CouponsListPage";
import SalesListPage from "pages/control/SalesListPage";
import NewCuponPage from "pages/benefits/NewCuponPage";
import NewSalePage from "pages/benefits/NewSalePage";
import NewSaleBurnablePage from "pages/burnable/NewSaleBurnablePage";
import NewSaleNoCouponBurnablePage from "pages/burnable/NewSaleNoCouponBurnablePage";
import ImportPage from "pages/benefits/Import/ImportPage";
import ImportBurnablePage from "pages/burnable/ImportBurnablePage";
import UsersPage from "pages/users/UsersPage";
import NewUserPage from "pages/users/modify_user/NewUserPage";
import EditUserPage from "pages/users/modify_user/EditUserPage";
import RolesPage from "pages/users/roles/RolesPage";
import RolesEditPage from "pages/users/roles/RolesEditPage";
import RolesNewPage from "pages/users/roles/RolesNewPage";
import VisitorQRPage from "pages/users/generate_qr/VisitorQRPage";
import ConsultProductPage from "pages/benefits/Consult/ConsultProductPage";
import SettlementsPage from "pages/reports/SettlementsPage";
import ABMMedicsPage from "pages/ABMs/ABMMedicsPage";
import ABMMedicsEditPage from "pages/ABMs/ABMMedicsEditPage";
import ABMMedicsNewPage from "pages/ABMs/ABMMedicsNewPage";
import ABMVademecumsPage from "pages/ABMs/ABMVademecumsPage";
import ABMVademecumsEditPage from "pages/ABMs/ABMVademecumsEditPage";
import ABMVademecumsNewPage from "pages/ABMs/ABMVademecumsNewPage";
import ABMPresentationsPage from "pages/ABMs/ABMPresentationsPage";
import ABMPresentationsEditPage from "pages/ABMs/ABMPresentationsEditPage";
import ABMPresentationsNewPage from "pages/ABMs/ABMPresentationsNewPage";
import ABMPharmaciesPage from "pages/ABMs/ABMPharmaciesPage";
import ABMPharmaciesNewPage from "pages/ABMs/ABMPharmaciesNewPage";
import ABMPharmaciesEditPage from "pages/ABMs/ABMPharmaciesEditPage";
import ABMDistributorsPage from "pages/ABMs/ABMDistributorsPage";
import ABMDistributorsNewPage from "pages/ABMs/ABMDistributorsNewPage";
import ABMDistributorsEditPage from "pages/ABMs/ABMDistributorsEditPage";
import ABMPharmacyDistributorPage from "pages/ABMs/ABMPharmacyDistributorPage";
import ABMPharmacyDistributorNewPage from "pages/ABMs/ABMPharmacyDistributorNewPage";
import ABMPharmaGroupsPage from "pages/ABMs/ABMPharmaGroupsPage";
import ABMBarcodeUploadPage from "pages/ABMs/ABMBarcodeUploadPage";
import ABMKamPage from "pages/ABMs/ABMKamPage";
import ABMKamEditPage from "pages/ABMs/ABMKamEditPage";
import ABMKamNewPage from "pages/ABMs/ABMKamNewPage";
import ABMVisitorPage from "pages/ABMs/ABMVisitorPage";
import ABMVisitorEditPage from "pages/ABMs/ABMVisitorEditPage";
import ABMVisitorNewPage from "pages/ABMs/ABMVisitorNewPage";
import ABMSalcobrandPage from "pages/ABMs/ABMSalcobrandPage";
import ABMSalcobrandEditPage from "pages/ABMs/ABMSalcobrandEditPage";
import ABMSalcobrandNewPage from "pages/ABMs/ABMSalcobrandNewPage";
import AccountProfileDetails from "pages/profile/Profile";
import DeletePage from "pages/delete/DeletePage";
import AccreditCouponPage from "pages/coupon_managment/AccreditCouponPage";
import SendCouponPage from "pages/coupon_managment/SendCouponPage";
import ConsultPatientPage from "pages/reports/ConsultPatientPage";
import ReportCreationPage from "pages/reports/ReportCreationPage";
import SalesListBurnablePage from "pages/burnable/SalesListBurnablePage";

// context
import { useLayoutState } from "context/LayoutContext";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import routes from "routes";
import pagesPermissions from "utils/pagesPermissions";
import AuthorizedRoute from "components/AuthorizedRoute";
import { Typography } from "components/Wrappers/Wrappers";
import KPIsDashboard from "pages/dashboard/AuditDashboard";
import TraceabilityDashboard from "pages/dashboard/TraceabilityDashboard";

function Layout(props) {
  var classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          style={{ padding: isMobile ? 0 : theme.spacing(3) }}
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path={routes.home} component={Dashboard} />
            <Route
              exact
              path={routes.audit}
              render={() => <Redirect to={routes.auditInscriptions} />}
            />
            <AuthorizedRoute
              path={routes.auditControlPanel}
              component={ControlPanelPage}
              props={props}
              enabledPermissions={pagesPermissions.auditControlPanelPage}
            />
            <AuthorizedRoute
              path={routes.auditInscriptions}
              component={InscriptionsPage}
              props={props}
              enabledPermissions={pagesPermissions.auditInscriptionsPage}
            />
            <AuthorizedRoute
              path={routes.auditSales}
              component={SalesPage}
              props={props}
              enabledPermissions={pagesPermissions.auditSalesPage}
            />
            <AuthorizedRoute
              path={routes.auditDataQuality}
              component={DataQualityPage}
              props={props}
              enabledPermissions={pagesPermissions.auditDataQualityPage}
            />
            <AuthorizedRoute
              path={routes.auditDigitalInscription}
              component={DigitalInscriptionPage}
              props={props}
              enabledPermissions={pagesPermissions.auditDigitalInscriptionPage}
            />
            <AuthorizedRoute
              path={routes.auditApprovalWorkflow}
              component={ApprovalWorkflowPage}
              props={props}
              enabledPermissions={pagesPermissions.auditApprovalWorkflowPage}
            />
            <AuthorizedRoute
              path={routes.auditPatterns}
              component={PatternsPage}
              props={props}
              enabledPermissions={pagesPermissions.auditPatternsPage}
            />
            <AuthorizedRoute
              path={routes.auditImport}
              component={AuditImportPage}
              props={props}
              enabledPermissions={pagesPermissions.auditImportPage}
            />
            <AuthorizedRoute
              path={routes.auditUF}
              component={UFPage}
              props={props}
              enabledPermissions={pagesPermissions.auditUFPage}
            />
            <AuthorizedRoute
              path={routes.traceability}
              component={TraceabilityPage}
              props={props}
              enabledPermissions={pagesPermissions.traceabilityPage}
            />
            <Route
              exact
              path={routes.control}
              render={() => <Redirect to={routes.controlCuponesIngresados} />}
            />
            <AuthorizedRoute
              path={[
                routes.controlCuponesIngresados,
                routes.controlCuponesIngresadosPharmacies,
              ]}
              component={CouponsListPage}
              props={props}
              enabledPermissions={pagesPermissions.couponsListPage}
            />
            <AuthorizedRoute
              path={[
                routes.controlVentasRealizadas,
                routes.controlVentasRealizadasPharmacies,
              ]}
              component={SalesListPage}
              props={props}
              enabledPermissions={pagesPermissions.salesListPage}
            />
            <AuthorizedRoute
              path={routes.createReports}
              component={ReportCreationPage}
              props={props}
              enabledPermissions={pagesPermissions.reportCreationPage}
            />
            <AuthorizedRoute
              path={routes.reportsDashboard}
              component={ReportsDashboard}
              props={props}
              enabledPermissions={pagesPermissions.reportsDashboard}
            />
            <AuthorizedRoute
              path={routes.reportsSMDashboard}
              component={ReportsDashboardSM}
              props={props}
              enabledPermissions={pagesPermissions.reportsDashboard}
            />
            <AuthorizedRoute
              path={routes.reportsDashboardOld}
              component={ReportsDashboardOld}
              props={props}
              enabledPermissions={pagesPermissions.reportsDashboard}
            />
            <AuthorizedRoute
              path={routes.reportsSMDashboardOld}
              component={ReportsDashboardSMOld}
              props={props}
              enabledPermissions={pagesPermissions.reportsDashboard}
            />
            <AuthorizedRoute
              path={routes.auditDashboard}
              component={KPIsDashboard}
              props={props}
              enabledPermissions={pagesPermissions.auditDashboard}
            />
            <AuthorizedRoute
              path={routes.traceabilityDashboard}
              component={TraceabilityDashboard}
              props={props}
              enabledPermissions={pagesPermissions.auditDashboard}
            />
            <Route
              exact
              path={routes.benefits}
              render={() => <Redirect to={routes.benefitsNewCupon} />}
            />
            <AuthorizedRoute
              path={[
                routes.benefitsNewCupon,
                routes.benefitsNewCuponPharmacies,
              ]}
              component={NewCuponPage}
              props={props}
              enabledPermissions={pagesPermissions.newCouponPage}
            />
            <AuthorizedRoute
              path={[routes.benefitsNewSale, routes.benefitsNewSalePharmacies]}
              component={NewSalePage}
              props={props}
              enabledPermissions={pagesPermissions.newSalePage}
            />
            <AuthorizedRoute
              path={[
                routes.benefitsNewSaleBurnable,
                routes.burnableNewSaleCouponPharmacies,
              ]}
              component={NewSaleBurnablePage}
              props={props}
              enabledPermissions={pagesPermissions.newSaleBurnablePage}
            />
            <AuthorizedRoute
              path={[
                routes.benefitsNewSaleBurnableNoCoupon,
                routes.burnableNewSaleNoCouponPharmacies,
              ]}
              component={NewSaleNoCouponBurnablePage}
              props={props}
              enabledPermissions={pagesPermissions.newSaleNoCouponBurnablePage}
            />
            <AuthorizedRoute
              path={[
                routes.controlVentasRealizadasBurnable,
                routes.controlVentasRealizadasBurnablePharmacies,
              ]}
              component={SalesListBurnablePage}
              props={props}
              enabledPermissions={pagesPermissions.salesListPage}
            />
            <AuthorizedRoute
              path={[routes.benefitsImport, routes.benefitsImportPharmacies]}
              component={ImportPage}
              props={props}
              enabledPermissions={pagesPermissions.importPage}
            />
            <AuthorizedRoute
              path={[
                routes.benefitsImportBurnable,
                routes.benefitsImportBurnablePharmacies,
              ]}
              component={ImportBurnablePage}
              props={props}
              enabledPermissions={pagesPermissions.importBurnablePage}
            />
            <AuthorizedRoute
              path={[
                routes.benefitsConsultProduct,
                routes.benefitsConsultProductPharmacies,
              ]}
              component={ConsultProductPage}
              props={props}
              enabledPermissions={pagesPermissions.consultProductPage}
            />
            <AuthorizedRoute
              path={routes.reportsConsultPatient}
              component={ConsultPatientPage}
              props={props}
              enabledPermissions={pagesPermissions.consultPatientPage}
            />

            <Route
              exact
              path={routes.users}
              render={() => <Redirect to={routes.usersUsers} />}
            />
            <AuthorizedRoute
              path={routes.usersUsers}
              component={UsersPage}
              props={props}
              enabledPermissions={pagesPermissions.usersPage}
            />
            <AuthorizedRoute
              path={routes.usersRoles}
              component={RolesPage}
              props={props}
              enabledPermissions={pagesPermissions.rolesPage}
            />
            <AuthorizedRoute
              path={routes.usersNewRole}
              component={RolesNewPage}
              props={props}
              enabledPermissions={pagesPermissions.rolesPage}
            />
            <AuthorizedRoute
              path={routes.usersEditRole}
              component={RolesEditPage}
              props={props}
              enabledPermissions={pagesPermissions.rolesPage}
            />
            <AuthorizedRoute
              path={routes.usersNewUser}
              component={NewUserPage}
              props={props}
              enabledPermissions={pagesPermissions.newUserPage}
            />
            <AuthorizedRoute
              path={routes.usersEditUser}
              component={EditUserPage}
              props={props}
              enabledPermissions={pagesPermissions.editUserPage}
            />
            <AuthorizedRoute 
              path={routes.generateVisitorQR}
              component={VisitorQRPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVisitorsPage}
            />

            <Route
              exact
              path={routes.reports}
              render={() => <Redirect to={routes.reportsSettlements} />}
            />
            <AuthorizedRoute
              path={[
                routes.reportsSettlements,
                routes.reportsSettlementsPharmacies,
              ]}
              component={SettlementsPage}
              props={props}
              enabledPermissions={pagesPermissions.settlementsPage}
            />

            <Route
              exact
              path={routes.abm}
              render={() => <Redirect to={routes.abmMedics} />}
            />
            <AuthorizedRoute
              path={routes.abmMedics}
              component={ABMMedicsPage}
              props={props}
              enabledPermissions={pagesPermissions.abmMedicsPage}
            />
            <AuthorizedRoute
              path={routes.abmMedicsEdit}
              component={ABMMedicsEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmMedicsEditPage}
            />
            <AuthorizedRoute
              path={routes.abmMedicsNew}
              component={ABMMedicsNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmMedicsNewPage}
            />
            <AuthorizedRoute
              path={routes.abmVademecums}
              component={ABMVademecumsPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVademecumsPage}
            />
            <AuthorizedRoute
              path={routes.abmVademecumsEdit}
              component={ABMVademecumsEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVademecumsEditPage}
            />
            <AuthorizedRoute
              path={routes.abmVademecumsNew}
              component={ABMVademecumsNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVademecumsNewPage}
            />
            <AuthorizedRoute
              path={routes.abmProducts}
              component={ABMPresentationsPage}
              props={props}
              enabledPermissions={pagesPermissions.abmProductsPage}
            />
            <AuthorizedRoute
              path={routes.abmProductsEdit}
              component={ABMPresentationsEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmProductsEditPage}
            />
            <AuthorizedRoute
              path={routes.abmProductsNew}
              component={ABMPresentationsNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmProductsNewPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmacies}
              component={ABMPharmaciesPage}
              props={props}
              enabledPermissions={pagesPermissions.abmPharmaciesPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmaciesEdit}
              component={ABMPharmaciesEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmPharmaciesEditPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmaciesNew}
              component={ABMPharmaciesNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmPharmaciesNewPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmaGroups}
              component={ABMPharmaGroupsPage}
              props={props}
              enabledPermissions={pagesPermissions.abmPharmaGroupsPage}
            />
            <AuthorizedRoute
              path={routes.abmDistributors}
              component={ABMDistributorsPage}
              props={props}
              enabledPermissions={pagesPermissions.abmDistributorsPage}
            />
            <AuthorizedRoute
              path={routes.abmDistributorsEdit}
              component={ABMDistributorsEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmDistributorsEditPage}
            />
            <AuthorizedRoute
              path={routes.abmDistributorsNew}
              component={ABMDistributorsNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmDistributorsNewPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmacyDistributors}
              component={ABMPharmacyDistributorPage}
              props={props}
              enabledPermissions={pagesPermissions.abmPharmacyDistributorPage}
            />
            <AuthorizedRoute
              path={routes.abmPharmacyDistributorsNew}
              component={ABMPharmacyDistributorNewPage}
              props={props}
              enabledPermissions={
                pagesPermissions.abmPharmacyDistributorNewPage
              }
            />
            <AuthorizedRoute
              path={routes.abmBarcodes}
              component={ABMBarcodeUploadPage}
              props={props}
              enabledPermissions={
                pagesPermissions.abmPharmacyDistributorNewPage
              }
            />
            <AuthorizedRoute
              path={routes.abmKam}
              component={ABMKamPage}
              props={props}
              enabledPermissions={pagesPermissions.abmKamPage}
            />
            <AuthorizedRoute
              path={routes.abmKamEdit}
              component={ABMKamEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmKamEditPage}
            />
            <AuthorizedRoute
              path={routes.abmKamNew}
              component={ABMKamNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmKamNewPage}
            />
            <AuthorizedRoute
              path={routes.abmVisitors}
              component={ABMVisitorPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVisitorsPage}
            />
            <AuthorizedRoute
              path={routes.abmVisitorsEdit}
              component={ABMVisitorEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVisitorsEditPage}
            />
            <AuthorizedRoute
              path={routes.abmVisitorsNew}
              component={ABMVisitorNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmVisitorsNewPage}
            />
            <AuthorizedRoute
              path={routes.abmSalcobrand}
              component={ABMSalcobrandPage}
              props={props}
              enabledPermissions={pagesPermissions.abmSalcobrandPage}
            />
            <AuthorizedRoute
              path={routes.abmSalcobrandEdit}
              component={ABMSalcobrandEditPage}
              props={props}
              enabledPermissions={pagesPermissions.abmSalcobrandEditPage}
            />
            <AuthorizedRoute
              path={routes.abmSalcobrandNew}
              component={ABMSalcobrandNewPage}
              props={props}
              enabledPermissions={pagesPermissions.abmSalcobrandNewPage}
            />

            <AuthorizedRoute
              path={routes.profile}
              component={AccountProfileDetails}
              props={props}
              enabledPermissions={[]}
            />
            <AuthorizedRoute
              path={routes.delete}
              component={DeletePage}
              props={props}
              enabledPermissions={pagesPermissions.delete}
            />
            <AuthorizedRoute
              path={routes.accreditCoupon}
              component={AccreditCouponPage}
              props={props}
              enabledPermissions={pagesPermissions.accreditCouponPage}
            />
            <AuthorizedRoute
              path={routes.sendCoupon}
              component={SendCouponPage}
              props={props}
              enabledPermissions={pagesPermissions.sendCouponPage}
            />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"right"}
            justifyContent="flex-end"
          >
            <div>
              <Typography>By Dynaxo Corp.</Typography>
            </div>
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
