import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "15em",
    marginTop: "5%",
  },
  popupDivCenter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  halfDivision: { display: "flex", marginLeft: "0em", marginTop: "6em" },
  divisionsDiv: { display: "flex", marginLeft: "1em", marginTop: "2em" },
  divisionsField: { width: 350, marginLeft: "5.8em", marginTop: "-0.1em" },
  distributorsDiv: { display: "flex", marginLeft: "1em", marginTop: "2em" },
  distributorsField: { width: 350, marginLeft: "4em", marginTop: "-0.1em" },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginBottom: "1rem",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginBottom: "2rem",
    width: 430,
    justifyContent: "space-between",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  searchField: {
    width: 500,
    marginTop: "-3%",
    marginBottom: "2%",
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  verySmallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 300,
    justifyContent: "space-between",
  },
  secondVerySmallItemInRow: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 230,
    justifyContent: "space-between",
  },
  verySmallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 100,
  },
  // RUT FIELD REQUIRED STYLES
  generalPurposeItems2: {
    display: "flex",
    width: 310,
    justifyContent: "flex-start",
  },
  smallItems2: {
    display: "flex",
    marginLeft: "1em",
    marginBottom: "2rem",
    width: 502,
    justifyContent: "space-between",
  },
  smallFieldValidation2: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 230,
  },
  smallFieldValidation3: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 80,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  submitLoader: {
    display: "flex",
    alignSelf: "center",
    marginRight: "43px",
  },
  realCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoSubtitle: {
    marginBottom: "2rem"
  },
  productSubtitle: {
    marginBottom: "1rem"
  }
}));
