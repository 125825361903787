import React from "react";
import routes from "routes";
import {
  Home as HomeIcon,
  CheckBox as CheckBoxIcon,
  Bookmarks as BookmarkIcon,
  Person as UsersIcon,
  Dashboard as DashboardIcon,
  CalendarToday as CalendarTodayIcon,
  AddBox as AddBoxIcon,
  List as ListIcon,
  Delete,
} from "@material-ui/icons";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { cloneDeep } from "lodash";

/* import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"; */

const sidebarStructure = {
  unknown: [{ id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> }],
  traceability: [
    {
      id: 0,
      label: "Trazabilidad",
      link: routes.traceability,
      icon: <AssessmentIcon />,
    },
    {
      id: 1,
      label: "Paneles",
      link: routes.dashboards,
      icon: <DashboardIcon />,
      children: [{ label: "Trazabilidad", link: routes.traceabilityDashboard }],
    },
  ],
  admin: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Auditoría",
      link: routes.audit,
      icon: <AssignmentLateIcon />,
      children: [
        { label: "Empadronamientos", link: routes.auditInscriptions },
        { label: "Ventas", link: routes.auditSales },
      ],
    },
    {
      id: 3,
      label: "Trazabilidad",
      link: routes.traceability,
      icon: <AssessmentIcon />,
    },
    {
      id: 4,
      label: "Tradicionales",
      link: routes.benefits,
      icon: <BookmarkIcon />,
      children: [
        { label: "Importar", link: routes.benefitsImport },
        { label: "Consultar Productos", link: routes.benefitsConsultProduct },
      ],
    },
    {
      id: 5,
      label: "Quemables",
      link: routes.burnable,
      icon: <BookmarkBorderIcon />,
      enabledPrograms: ["1"],
      children: [
        { label: "Nueva Venta", link: routes.benefitsNewSaleBurnable },
        {
          label: "Venta Sin Cupón",
          link: routes.benefitsNewSaleBurnableNoCoupon,
        },
        { label: "Importar", link: routes.benefitsImportBurnable },
        {
          label: "Ventas Realizadas",
          link: routes.controlVentasRealizadasBurnable,
        },
      ],
    },
    {
      id: 6,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 7,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [
        { label: "Liquidaciones", link: routes.reportsSettlements },
        { label: "Consultar Pacientes", link: routes.reportsConsultPatient },
      ],
    },
    {
      id: 8,
      label: "Paneles",
      link: routes.dashboards,
      icon: <DashboardIcon />,
      children: [
        { label: "General RS", link: routes.reportsDashboard },
        { label: "General SM", link: routes.reportsSMDashboard },
        { label: "Auditoría", link: routes.auditDashboard },
        { label: "Trazabilidad", link: routes.traceabilityDashboard },
      ],
    },
    {
      id: 9,
      label: "Altas y modificaciones",
      link: routes.abm,
      icon: <AddBoxIcon />,
      children: [
        { label: "Vademecums", link: routes.abmVademecums },
        { label: "Lista de precios", link: routes.abmProducts },
        { label: "Farmacias", link: routes.abmPharmacies },
        { label: "Distribuidores", link: routes.abmDistributors },
        {
          label: "Farmacia-Distribuidor",
          link: routes.abmPharmacyDistributors,
        },
        { label: "Grupos", link: routes.abmPharmaGroups },
      ],
    },
    {
      id: 10,
      label: "Usuarios",
      link: routes.usersUsers,
      icon: <UsersIcon />,
    },
  ],
  kam: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Empadronamientos",
      link: routes.auditInscriptions,
      icon: <ReceiptIcon />,
    },

    {
      id: 3,
      label: "Ventas",
      link: routes.auditSales,
      icon: <MonetizationOnIcon />,
    },
    { id: 4, type: "divider" },
  ],
  commertial: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Empadronamientos",
      link: routes.auditInscriptions,
      icon: <ReceiptIcon />,
    },
    {
      id: 3,
      label: "Ventas",
      link: routes.auditSales,
      icon: <MonetizationOnIcon />,
    },
    { id: 4, type: "divider" },
  ],
  ges: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Ventas",
      link: routes.auditSales,
      icon: <MonetizationOnIcon />,
    },
    { id: 3, type: "divider" },
  ],
  pharmacies: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 3,
      label: "Tradicionales",
      link: routes.traditional,
      icon: <BookmarkIcon />,

      children: [
        {
          id: 1,
          label: "Beneficios",
          link: routes.benefitsPharmacies,
          icon: <BookmarkIcon />,
          children: [
            {
              label: "Nuevo Cupón",
              link: routes.benefitsNewCuponPharmacies,
              enabledPrograms: ["2"],
            },
            { label: "Nueva Venta", link: routes.benefitsNewSalePharmacies },
            { label: "Importar", link: routes.benefitsImportPharmacies },
            {
              label: "Consultar Productos",
              link: routes.benefitsConsultProductPharmacies,
            },
          ],
        },

        {
          id: 2,
          label: "Control",
          link: routes.traditionalControl,
          icon: <CheckBoxIcon />,
          children: [
            {
              label: "Empadronamientos",
              link: routes.controlCuponesIngresadosPharmacies,
            },
            {
              label: "Ventas Realizadas",
              link: routes.controlVentasRealizadasPharmacies,
            },
            {
              label: "Liquidaciones",
              link: routes.reportsSettlementsPharmacies,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      label: "Quemables",
      link: routes.burnable,
      enabledPrograms: ["1"],
      icon: <BookmarkBorderIcon />,
      children: [
        {
          id: 1,
          label: "Realizar nueva venta",
          link: routes.burnableNewSale,
          icon: <BookmarkAddOutlinedIcon />,
          children: [
            {
              label: "Venta con cupón",
              link: routes.burnableNewSaleCouponPharmacies,
            },
            {
              label: "Venta sin cupón",
              link: routes.burnableNewSaleNoCouponPharmacies,
            },
          ],
        },
        {
          id: 2,
          label: "Ventas Realizadas",
          link: routes.burnableSalesPharmacies,
          icon: <ReceiptIcon />,
          children: [
            {
              label: "Resumen ventas",
              link: routes.controlVentasRealizadasBurnablePharmacies,
            },
            {
              label: "Importar ventas",
              link: routes.benefitsImportBurnablePharmacies,
            },
          ],
        },
      ],
    },
  ],
  full: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Auditoría",
      link: routes.audit,
      icon: <AssignmentLateIcon />,
      children: [
        { label: "Empadronamientos", link: routes.auditInscriptions },
        { label: "Ventas", link: routes.auditSales },
      ],
    },
    {
      id: 3,
      label: "Trazabilidad",
      link: routes.traceability,
      icon: <AssessmentIcon />,
    },
    {
      id: 4,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 5,
      label: "Quemables",
      enabledPrograms: ["1"],
      link: routes.burnable,
      icon: <BookmarkBorderIcon />,
      children: [
        {
          label: "Ventas Realizadas",
          link: routes.controlVentasRealizadasBurnable,
        },
      ],
    },
    {
      id: 5,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [
        { label: "Liquidaciones", link: routes.reportsSettlements },
        { label: "Consultar Pacientes", link: routes.reportsConsultPatient },
      ],
    },
    {
      id: 6,
      label: "Paneles",
      link: routes.dashboards,
      icon: <DashboardIcon />,
      children: [
        { label: "General RS", link: routes.reportsDashboard },
        { label: "General SM", link: routes.reportsSMDashboard },
        { label: "Auditoría", link: routes.auditDashboard },
        { label: "Trazabilidad", link: routes.traceabilityDashboard },
      ],
    },
  ],
  pharmacies_read_only: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 3,
      label: "Tradicionales",
      link: routes.traditional,
      icon: <BookmarkIcon />,

      children: [
        {
          id: 1,
          label: "Beneficios",
          link: routes.benefitsPharmacies,
          icon: <BookmarkIcon />,
          children: [
            {
              label: "Consultar Productos",
              link: routes.benefitsConsultProductPharmacies,
            },
          ],
        },

        {
          id: 2,
          label: "Control",
          link: routes.traditionalControl,
          icon: <CheckBoxIcon />,
          children: [
            {
              label: "Empadronamientos",
              link: routes.controlCuponesIngresadosPharmacies,
            },
            {
              label: "Ventas Realizadas",
              link: routes.controlVentasRealizadasPharmacies,
            },
            {
              label: "Liquidaciones",
              link: routes.reportsSettlementsPharmacies,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      label: "Quemables",
      link: routes.burnableSalesPharmacies,
      icon: <BookmarkBorderIcon />,
      enabledPrograms: ["1"],
      children: [
        {
          label: "Resumen ventas",
          link: routes.controlVentasRealizadasBurnablePharmacies,
        },
      ],
    },
  ],
  mauricio: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Tradicionales",
      link: routes.benefits,
      icon: <BookmarkIcon />,
      children: [
        {
          label: "Nuevo Cupón",
          link: routes.benefitsNewCupon,
          enabledPrograms: ["2"],
        },
        { label: "Nueva Venta", link: routes.benefitsNewSale },
        // { label: "Nueva Venta Quemable", link: routes.benefitsNewSaleBurnable },
        { label: "Importar", link: routes.benefitsImport },
        { label: "Consultar Productos", link: routes.benefitsConsultProduct },
      ],
    },
    {
      id: 3,
      label: "Quemables",
      link: routes.burnable,
      icon: <BookmarkBorderIcon />,
      enabledPrograms: ["1"],
      children: [
        { label: "Nueva Venta", link: routes.benefitsNewSaleBurnable },
        {
          label: "Venta Sin Cupón",
          link: routes.benefitsNewSaleBurnableNoCoupon,
        },
        { label: "Importar", link: routes.benefitsImportBurnable },
        {
          label: "Ventas Realizadas",
          link: routes.controlVentasRealizadasBurnable,
        },
      ],
    },
    {
      id: 4,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 5,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [
        { label: "Liquidaciones", link: routes.reportsSettlements },
        { label: "Consultar Pacientes", link: routes.reportsConsultPatient },
      ],
    },
  ],
  distributors: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 3,
      label: "Tradicionales",
      link: routes.benefits,
      icon: <BookmarkIcon />,
      children: [
        { label: "Importar", link: routes.benefitsImport },
        { label: "Consultar Productos", link: routes.benefitsConsultProduct },
      ],
    },
    {
      id: 2,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 5,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [{ label: "Liquidaciones", link: routes.reportsSettlements }],
    },
    {
      id: 6,
      label: "Listados",
      link: routes.abm,
      icon: <ListIcon />,
      children: [{ label: "Farmacias", link: routes.abmPharmacies }],
    },
  ],
  pharmagroups: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 3,
      label: "Tradicionales",
      link: routes.benefits,
      icon: <BookmarkIcon />,
      children: [
        { label: "Importar", link: routes.benefitsImport },
        { label: "Consultar Productos", link: routes.benefitsConsultProduct },
      ],
    },
    {
      id: 2,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 5,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [{ label: "Liquidaciones", link: routes.reportsSettlements }],
    },
    {
      id: 6,
      label: "Listados",
      link: routes.abm,
      icon: <ListIcon />,
      children: [{ label: "Farmacias", link: routes.abmPharmacies }],
    },
  ],

  consultant: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      icon: <AllInboxIcon />,
      label: "Acreditar cupones",
      link: routes.accreditCoupon,
    },
  ],

  medic: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      icon: <AllInboxIcon />,
      label: "Enviar cupones",
      link: routes.sendCoupon,
    },
  ],

  super_admin: [
    { id: 0, label: "Inicio", link: routes.home, icon: <HomeIcon /> },
    { id: 1, type: "divider" },
    {
      id: 2,
      label: "Auditoría",
      link: routes.audit,
      icon: <AssignmentLateIcon />,
      children: [
        { label: "Empadronamientos", link: routes.auditInscriptions },
        { label: "Ventas", link: routes.auditSales },
        { label: "Importar", link: routes.auditImport },
        { label: "Cargar UF", link: routes.auditUF },
        // { label: "Ventas Quemables", link: routes.burnableAuditSales },
      ],
    },
    {
      id: 3,
      label: "Gestión de cupones",
      link: routes.couponManagment,
      icon: <AllInboxIcon />,
      children: [
        { label: "Acreditar cupones", link: routes.accreditCoupon },
        { label: "Enviar cupones", link: routes.sendCoupon },
      ],
    },
    {
      id: 4,
      label: "Trazabilidad",
      link: routes.traceability,
      icon: <AssessmentIcon />,
    },
    {
      id: 5,
      label: "Tradicionales",
      link: routes.benefits,
      icon: <BookmarkIcon />,
      children: [
        {
          label: "Nuevo Cupón",
          link: routes.benefitsNewCupon,
          enabledPrograms: ["2"],
        },
        { label: "Nueva Venta", link: routes.benefitsNewSale },
        { label: "Importar", link: routes.benefitsImport },
        { label: "Consultar Productos", link: routes.benefitsConsultProduct },
      ],
    },
    {
      id: 6,
      label: "Quemables",
      link: routes.burnable,
      icon: <BookmarkBorderIcon />,
      enabledPrograms: ["1"],
      children: [
        { label: "Nueva Venta", link: routes.benefitsNewSaleBurnable },
        {
          label: "Venta Sin Cupón",
          link: routes.benefitsNewSaleBurnableNoCoupon,
        },
        { label: "Importar", link: routes.benefitsImportBurnable },
        {
          label: "Ventas Realizadas",
          link: routes.controlVentasRealizadasBurnable,
        },
      ],
    },
    {
      id: 7,
      label: "Control",
      link: routes.control,
      icon: <CheckBoxIcon />,
      children: [
        { label: "Empadronamientos", link: routes.controlCuponesIngresados },
        { label: "Ventas Realizadas", link: routes.controlVentasRealizadas },
      ],
    },
    {
      id: 8,
      label: "Reportes",
      link: routes.reports,
      icon: <CalendarTodayIcon />,
      children: [
        { label: "Liquidaciones", link: routes.reportsSettlements },
        { label: "Consultar Pacientes", link: routes.reportsConsultPatient },
        { label: "Generar", link: routes.createReports },
      ],
    },
    {
      id: 9,
      label: "Paneles",
      link: routes.dashboards,
      icon: <DashboardIcon />,
      children: [
        { label: "General RS", link: routes.reportsDashboard },
        { label: "General SM", link: routes.reportsSMDashboard },
        { label: "ex-General RS", link: routes.reportsDashboardOld },
        { label: "ex-General SM", link: routes.reportsSMDashboardOld },
        { label: "Auditoría", link: routes.auditDashboard },
        { label: "Trazabilidad", link: routes.traceabilityDashboard },
      ],
    },

    {
      id: 10,
      label: "Altas y modificaciones",
      link: routes.abm,
      icon: <AddBoxIcon />,
      children: [
        { label: "Médicos", link: routes.abmMedics },
        { label: "Vademecums", link: routes.abmVademecums },
        { label: "Lista de precios", link: routes.abmProducts },
        { label: "Farmacias", link: routes.abmPharmacies },
        { label: "Distribuidores", link: routes.abmDistributors },
        {
          label: "Farmacia-Distribuidor",
          link: routes.abmPharmacyDistributors,
        },
        { label: "Grupos", link: routes.abmPharmaGroups },
        { label: "Códigos de barra", link: routes.abmBarcodes },
        { label: "KAM", link: routes.abmKam },
        { label: "Visitador", link: routes.abmVisitors },
        { label: "Salcobrand", link: routes.abmSalcobrand },
      ],
    },
    {
      id: 11,
      label: "Usuarios",
      link: routes.users,
      icon: <UsersIcon />,
      children: [
        { label: "Usuarios", link: routes.usersUsers },
        { label: "Roles", link: routes.usersRoles },
        { label: "Generar QR", link: routes.generateVisitorQR},
      ],
    },
    {
      id: 12,
      label: "Eliminar",
      link: routes.delete,
      icon: <Delete />,
    },
  ],
};

const filterItems = (items, programId) => {
  return items.filter((item) => {
    if (item.children) {
      item.children = filterItems(item.children, programId);
    }

    if (item.enabledPrograms) {
      return item.enabledPrograms.includes(programId);
    }

    return true;
  });
};

export const getSiderbarStructure = (role, programId = null) => {
  let sidebar = cloneDeep(sidebarStructure[role]);
  if (!sidebar) {
    sidebar = sidebarStructure.unknown;
  }

  if (!programId || programId === "-1") return sidebarStructure.unknown;

  sidebar = filterItems(sidebar, programId);
  return sidebar;
};

export default sidebarStructure;
