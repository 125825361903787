const getDoctor = "doctor_list";
const editDoctor = "doctor_edit";
const getRole = "role_list";
const editRole = "role_edit";
const getUser = "user_list";
const editUser = "user_edit";
const getPatient = "patient_list";
const editPatient = "patient_edit";
const getBarcode = "barcode_list";
const editBarcode = "barcode_edit";
const getCommune = "barcode_list";
const editCommune = "barcode_edit";
const getDistributor = "distributor_list";
const editDistributor = "distributor_edit";
const getPharmaGroup = "pharmacy_group_list";
const editPharmaGroup = "pharmacy_group_edit";
const getPharmacy = "pharmacy_list";
const editPharmacy = "pharmacy_edit";
const getPresentation = "presentation_list";
const editPresentation = "presentation_edit";
const getPrice = "price_list";
const editPrice = "price_edit";
const getProduct = "product_list";
const editProduct = "product_edit";
const getProgram = "program_list";
const getVademecum = "vademecum_list";
const editVademecum = "vademecum_edit";
const getInscription = "coupon_query";
const editInscription = "coupon_edit";
const upload = "cupon_and_transaction_upload";
const uploadInscription = "coupon_upload";
const deleteInscription = "coupon_delete";
const getSale = "transaction_list";
const editSale = "transaction_edit";

const getDashboardAudit = "dashboard_audit";
const getInscriptionsAudit = "inscriptions_audit_list";
const getSalesAudit = "sales_audit_list";
const getDataQualityAudit = "data_quality_audit";
const getDigitalInscriptionsAudit = "digital_inscriptions_audit";
const getApprovalWorkflowAudit = "approval_audit_list";
const getPatternsAudit = "patterns_audit";
const uploadInscriptionsAudit = "upload_audit_inscription";
const uploadSalesAudit = "upload_audit_sale";
const getUF = "uf_list";
const editUF = "uf_edit";
const getKam = "kam_list";
const editKam = "kam_edit";
const getMedic = "medic_list";
const editMedic = "medic_edit";
const getVisitor = "consultant_list";
const editVisitor = "consultant_edit";

const accreditCoupon = "accredit_coupon";
const sendCoupon = "send_coupon";
const createControlPanel = "control_panel_create";
const viewControlPanel = "control_panel_view";
const getTraceability = "traceability_list";

const pagesPermissions = {
  usersPage: [getUser, editUser, getRole],
  rolesPage: [getUser, editUser, getRole, editRole],
  newUserPage: [getUser, editUser, getRole],
  editUserPage: [getUser, editUser, getRole],
  auditControlPanelPage: [getDashboardAudit],
  auditInscriptionsPage: [getInscriptionsAudit],
  auditSalesPage: [getSalesAudit],
  auditDataQualityPage: [getDataQualityAudit],
  auditDigitalInscriptionPage: [getDigitalInscriptionsAudit],
  auditApprovalWorkflowPage: [getApprovalWorkflowAudit],
  auditPatterns: [getPatternsAudit],
  auditPatternsPage: [getPatternsAudit],
  auditImportPage: [uploadInscriptionsAudit, uploadSalesAudit],
  traceabilityPage: [getTraceability], // Update this when the role is created
  auditUFPage: [getUF, editUF],
  couponsListPage: [getInscription],
  salesListPage: [getSale],
  newCouponPage: [
    getDoctor,
    getPresentation,
    getPatient,
    getBarcode,
    getInscription,
    editInscription,
  ],
  newSalePage: [getPatient, getInscription, editSale],
  newSaleBurnablePage: [getPatient, getInscription, editSale],
  newSaleNoCouponBurnablePage: [getPatient, getInscription, editSale],
  importPage: [upload],
  importBurnablePage: [upload],
  consultDoctorPage: [getDoctor],
  consultProductPage: [getPatient, getInscription],
  consultPatientPage: [
    getPatient,
    getInscription,
    getSale,
    getInscriptionsAudit,
    getSalesAudit,
  ],
  settlementsPage: [getSale],
  abmMedicsPage: [getMedic],
  abmMedicsEditPage: [getMedic, editMedic],
  abmMedicsNewPage: [getMedic, editMedic],
  abmVademecumsPage: [getPresentation, editPresentation],
  abmVademecumsEditPage: [getPresentation, editPresentation],
  abmVademecumsNewPage: [getPresentation, editPresentation],
  abmProductsPage: [getPresentation],
  abmProductsEditPage: [getPresentation, editPresentation],
  abmProductsNewPage: [getPresentation, editPresentation],
  abmPharmaciesPage: [getPharmacy],
  abmPharmaciesEditPage: [
    getPharmacy,
    getPharmaGroup,
    getCommune,
    editPharmacy,
  ],
  abmPharmaciesNewPage: [getPharmacy, getPharmaGroup, getCommune, editPharmacy],
  abmPharmaGroupsPage: [getPharmaGroup, editPharmaGroup],
  abmDistributorsPage: [getDistributor, editDistributor],
  abmDistributorsEditPage: [getDistributor, editDistributor],
  abmDistributorsNewPage: [getDistributor, editDistributor],
  abmPharmacyDistributorPage: [getDistributor, getPharmacy],
  abmPharmacyDistributorNewPage: [getDistributor, getPharmacy, editPharmacy], // editpharmacy is used by edit p-d
  abmBarcodes: [upload],
  abmKamPage: [getKam, editKam],
  abmKamEditPage: [getKam, editKam],
  abmKamNewPage: [getKam, editKam],
  abmVisitorsPage: [getVisitor, editVisitor],
  abmVisitorsEditPage: [getVisitor, editVisitor],
  abmVisitorsNewPage: [getVisitor, editVisitor],
  abmSalcobrandPage: [getPresentation, editPresentation],
  abmSalcobrandEditPage: [getPresentation, editPresentation],
  abmSalcobrandNewPage: [getPresentation, editPresentation],
  delete: [editRole], // temp: only a superadmin permission
  accreditCouponPage: [accreditCoupon, editMedic],
  sendCouponPage: [sendCoupon],
  reportCreationPage: [createControlPanel],
  reportsDashboard: [viewControlPanel],
  auditDashboard: [viewControlPanel],
};

export default pagesPermissions;
