import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

export default function PopupMessage(props) {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <>
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h4">{props.title}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body1">{props.message}</Typography>
            </DialogContentText>
            {props.children}
          </DialogContent>
          <DialogActions className={props.buttonDivClassName}>
            {props.executeButton}
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              className={props.buttonClassName}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
